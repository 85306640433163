import React from 'react';
import { Box, Select, PicnicCss, IconButton } from '@attentive/picnic';
import {
  SubscriberUploadJobStatus,
  SubscriberUploadSubscriptionFlow,
} from '../__generated__/useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob.graphql';
import {
  SubscriptionFlow,
  SubscriberType,
  SubscriptionChannel,
  SubscriptionFlowLabels,
  UploadStatus,
} from '../../../../subscriber-upload/constants';

const SELECT_WIDTH = '200px';
const SUBSCRIPTION_TYPE_SELECT_WIDTH = '210px';
const SUBSCRIBER_FLOW_SELECT_WIDTH = '255px';

const containerCss: PicnicCss = { display: 'flex', alignItems: 'center', mr: '$space4' };

const SubscriptionChannelLabels = {
  [SubscriptionChannel.ALL]: 'All channels',
  [SubscriptionChannel.EMAIL]: 'Email',
  [SubscriptionChannel.TEXT]: 'SMS',
};

const SubscriptionTypeLabels = {
  [SubscriberType.ALL]: 'All subscription types',
  [SubscriberType.MARKETING]: 'Marketing',
  [SubscriberType.TRANSACTIONAL]: 'Transactional',
};

const UploadStatusLabels: Partial<Record<SubscriberUploadJobStatus | 'ALL', string>> = {
  ALL: 'All statuses',
  STATUS_ABANDONED: 'Abandoned',
  STATUS_COMPLETE: 'Complete',
  STATUS_PROCESS: 'Processing',
  STATUS_IN_PROGRESS: 'In progress',
  STATUS_UPLOADED: 'Uploaded',
  STATUS_INVALID: 'Invalid',
};

const SubscriptionFlowOptions: Partial<Record<SubscriberUploadSubscriptionFlow | 'ALL', string>> = {
  ALL: SubscriptionFlowLabels.ALL,
  SUBSCRIPTION_FLOW_SILENT_OPT_IN: SubscriptionFlowLabels.SILENT_OPT_IN,
  SUBSCRIPTION_FLOW_DOUBLE_OPT_IN: SubscriptionFlowLabels.DOUBLE_OPT_IN,
  SUBSCRIPTION_FLOW_SINGLE_OPT_IN: SubscriptionFlowLabels.SINGLE_OPT_IN,
};

type FilterProps = {
  css?: PicnicCss;
  onChange: (status: string) => void;
  value: string;
};

export function StatusFilter({ css, onChange, value }: FilterProps) {
  return (
    <Box css={{ ...containerCss, ...css }}>
      <Select css={{ minWidth: SELECT_WIDTH }} value={value} onChange={onChange} size="small">
        {Object.entries(UploadStatusLabels).map(([filter, label]) => (
          <Select.Item key={filter} value={filter}>
            {label}
          </Select.Item>
        ))}
      </Select>
    </Box>
  );
}

export function ChannelFilter({ css, onChange, value }: FilterProps) {
  return (
    <Box css={{ ...containerCss, ...css }}>
      <Select css={{ minWidth: SELECT_WIDTH }} value={value} onChange={onChange} size="small">
        {Object.values(SubscriptionChannel).map((filter) => (
          <Select.Item key={filter} value={filter}>
            {SubscriptionChannelLabels[filter as SubscriptionChannel]}
          </Select.Item>
        ))}
      </Select>
    </Box>
  );
}

export function SubscriptionTypeFilter({ css, onChange, value }: FilterProps) {
  return (
    <Box css={{ ...containerCss, ...css }}>
      <Select
        css={{ minWidth: SUBSCRIPTION_TYPE_SELECT_WIDTH }}
        value={value}
        onChange={onChange}
        size="small"
      >
        {Object.values(SubscriberType).map((filter) => (
          <Select.Item key={filter} value={filter}>
            {SubscriptionTypeLabels[filter as SubscriberType]}
          </Select.Item>
        ))}
      </Select>
    </Box>
  );
}

export function SubscriberFlowFilter({ css, onChange, value }: FilterProps) {
  return (
    <Box css={{ ...containerCss, ...css }}>
      <Select
        css={{ minWidth: SUBSCRIBER_FLOW_SELECT_WIDTH }}
        value={value}
        onChange={onChange}
        size="small"
      >
        {Object.entries(SubscriptionFlowOptions).map(([filter, label]) => (
          <Select.Item key={filter} value={filter}>
            {label}
          </Select.Item>
        ))}
      </Select>
    </Box>
  );
}

interface SubscriberUploadsFiltersProps {
  channel: string;
  channelOnChange: (status: SubscriptionChannel) => void;
  onClear: () => void;
  subscriptionFlow: string;
  subscriptionFlowOnChange: (status: SubscriptionFlow) => void;
  subscriptionType: string;
  subscriptionTypeOnChange: (status: SubscriberType) => void;
  uploadStatus: string;
  uploadStatusOnChange: (status: UploadStatus) => void;
}

export const SubscriberUploadsFilters: React.VFC<SubscriberUploadsFiltersProps> = ({
  channel,
  channelOnChange,
  onClear,
  subscriptionFlow,
  subscriptionFlowOnChange,
  subscriptionType,
  subscriptionTypeOnChange,
  uploadStatus,
  uploadStatusOnChange,
}) => {
  const isFilterApplied = [subscriptionFlow, subscriptionType, channel, uploadStatus].some(
    (item) => item !== 'ALL'
  );

  return (
    <Box css={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <Box css={{ display: 'flex' }}>
        <StatusFilter
          value={uploadStatus}
          onChange={(filter) => uploadStatusOnChange(filter as UploadStatus)}
        />
        <ChannelFilter
          value={channel}
          onChange={(filter) => {
            channelOnChange(filter as SubscriptionChannel);
          }}
        />
        <SubscriptionTypeFilter
          value={subscriptionType}
          onChange={(filter) => subscriptionTypeOnChange(filter as SubscriberType)}
        />
        <SubscriberFlowFilter
          css={{ marginRight: '$space1' }}
          value={subscriptionFlow}
          onChange={(filter) => subscriptionFlowOnChange(filter as SubscriptionFlow)}
        />
        {isFilterApplied && (
          <IconButton description="Clear filters" iconName="X" onClick={onClear} size="small" />
        )}
      </Box>
    </Box>
  );
};
