import {
  SubscriptionFlow,
  SubscriberType,
  SubscriptionChannel,
  UploadStatus,
} from '../../../subscriber-upload/constants';
import { FormattedSubscriberUpload } from './useSubscriptionUploads';

interface HistoryState {
  batchHistory: FormattedSubscriberUpload[];
  uploadStatusFilter: UploadStatus;
  channelFilter: SubscriptionChannel;
  subscriptionTypeFilter: SubscriberType;
  subscriberFlowFilter: SubscriptionFlow;
  offset: number;
  filteredHistory: FormattedSubscriberUpload[];
}

export type setFilterProps = {
  channelFilter?: SubscriptionChannel;
  subscriberFlowFilter?: SubscriptionFlow;
  subscriptionTypeFilter?: SubscriberType;
  uploadStatusFilter?: UploadStatus;
};

export enum HistoryAction {
  CLEAR_FILTERS,
  SET_BATCH_HISTORY,
  SET_FILTER,
  SET_OFFSET,
}

export const initialState: HistoryState = {
  batchHistory: [],
  channelFilter: SubscriptionChannel.ALL,
  filteredHistory: [],
  offset: 0,
  subscriberFlowFilter: SubscriptionFlow.ALL,
  subscriptionTypeFilter: SubscriberType.ALL,
  uploadStatusFilter: UploadStatus.ALL,
};

export function reducer(
  state: HistoryState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: { type: HistoryAction; payload?: any }
): HistoryState {
  switch (action.type) {
    case HistoryAction.SET_BATCH_HISTORY:
      return {
        ...state,
        batchHistory: [...action.payload],
        filteredHistory: [...action.payload],
        offset: 0,
      };
    case HistoryAction.SET_OFFSET:
      return { ...state, offset: action.payload };
    case HistoryAction.CLEAR_FILTERS:
      return {
        ...state,
        offset: 0,
        filteredHistory: [...state.batchHistory],
        channelFilter: SubscriptionChannel.ALL,
        subscriberFlowFilter: SubscriptionFlow.ALL,
        subscriptionTypeFilter: SubscriberType.ALL,
        uploadStatusFilter: UploadStatus.ALL,
      };
    case HistoryAction.SET_FILTER: {
      const newState = { ...state, ...action.payload };
      let filteredHistory = [...state.batchHistory];

      if (newState.channelFilter !== SubscriptionChannel.ALL) {
        filteredHistory = filteredHistory.filter(
          ({ channel }) => channel === newState.channelFilter
        );
      }

      if (newState.subscriptionTypeFilter !== SubscriberType.ALL) {
        filteredHistory = filteredHistory.filter(
          ({ subscriptionType }) => subscriptionType === newState.subscriptionTypeFilter
        );
      }

      if (newState.subscriberFlowFilter !== SubscriptionFlow.ALL) {
        filteredHistory = filteredHistory.filter(
          ({ subscriptionFlow }) => subscriptionFlow === newState.subscriberFlowFilter
        );
      }

      if (newState.uploadStatusFilter !== UploadStatus.ALL) {
        filteredHistory = filteredHistory.filter(
          ({ uploadStatus }) => uploadStatus === newState.uploadStatusFilter
        );
      }

      return { ...newState, offset: 0, filteredHistory };
    }
    default:
      return state;
  }
}
