import _useSubscriptionUploads_getSubscriberType_SettingsUI_SubscriberUploadJobParameters from "./__generated__/useSubscriptionUploads_getSubscriberType_SettingsUI_SubscriberUploadJobParameters.graphql";
import _useSubscriptionUploads_getChannel_SettingsUI_SubscriberUploadJobParameters from "./__generated__/useSubscriptionUploads_getChannel_SettingsUI_SubscriberUploadJobParameters.graphql";
import _useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob from "./__generated__/useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob.graphql";
import _useSubscriptionUploads_SubscriberUpload_SettingsUI_Company from "./__generated__/useSubscriptionUploads_SubscriberUpload_SettingsUI_Company.graphql";
import { graphql, readInlineData } from '@attentive/data';
import { useFragment } from 'react-relay';
import SubscriberUploadsFragment from './__generated__/useSubscriptionUploads_SubscriberUpload_SettingsUI_Company.graphql';
import UploadNodeFragment from './__generated__/useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob.graphql';
import GetChannelFragment from './__generated__/useSubscriptionUploads_getChannel_SettingsUI_SubscriberUploadJobParameters.graphql';
import GetSubscriberTypeFragment from './__generated__/useSubscriptionUploads_getSubscriberType_SettingsUI_SubscriberUploadJobParameters.graphql';
import { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { SubscriberType, SubscriptionChannel } from '../../../subscriber-upload/constants';

const formatUploadNode = queryRef => {
  const subscriberUpload = readInlineData(UploadNodeFragment, queryRef);
  const {
    batchId,
    id,
    parameters,
    status,
    subscriptionFlow
  } = subscriberUpload;
  return {
    id,
    batchId,
    queryRef: subscriberUpload,
    // These values are used for sorting the uploads
    channel: getChannel(parameters),
    subscriptionType: getSubscriberType(parameters),
    uploadStatus: status,
    subscriptionFlow
  };
};

_useSubscriptionUploads_SubscriberUpload_SettingsUI_Company;
export const useSubscriptionUploads = queryRef => {
  const {
    subscriberUploads
  } = useFragment(SubscriberUploadsFragment, queryRef);
  const [formattedItems, setFormattedItems] = useState([]);
  useEffect(() => {
    const formatted = subscriberUploads ? subscriberUploads.edges.map(_ref => {
      let {
        node
      } = _ref;
      return formatUploadNode(node);
    }) : [];
    const sortedNodes = orderBy(formatted, ['batchId'], ['desc']);
    setFormattedItems(sortedNodes);
  }, [subscriberUploads]);
  return formattedItems;
};
_useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob;
_useSubscriptionUploads_getChannel_SettingsUI_SubscriberUploadJobParameters;

function getChannel(queryRef) {
  const {
    subscriptionTypes
  } = readInlineData(GetChannelFragment, queryRef);
  if (subscriptionTypes.includes(SubscriptionChannel.EMAIL)) return SubscriptionChannel.EMAIL;
  if (subscriptionTypes.includes(SubscriptionChannel.TEXT)) return SubscriptionChannel.TEXT;
}

_useSubscriptionUploads_getSubscriberType_SettingsUI_SubscriberUploadJobParameters;

function getSubscriberType(queryRef) {
  const {
    subscriptionTypes
  } = readInlineData(GetSubscriberTypeFragment, queryRef);
  if (subscriptionTypes.includes(SubscriberType.MARKETING)) return SubscriberType.MARKETING;
  if (subscriptionTypes.includes(SubscriberType.TRANSACTIONAL)) return SubscriberType.TRANSACTIONAL;
}